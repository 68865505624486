export const aboutInfo = 
    {
        Main: {
            Title: 'About me',
            Description: "Back in college I took a basic coding class and had so much fun I promised myself that one day I would learn coding. I wasn't planning on making a career shift but during the summer of 2021 I came to a fork in the road in regards to my life and career path. The previous chapters of my life were forced to close and so I figured...why not? I am excited to learn and devlop and grow. This journey is just getting started and I only wish I would have started sooner!"
        },
        Values: {
            Honesty: "I think most would be hard pressed to find an ethos that carries with it a heavier weight than that of honesty. Despite the intrinsic value that most seem to internalize about honesty it is seemingly lacking in outward expression. I'll be honest about honesty: it's hard. And when faced with a need to reach for an empathetic justification of the difficulty therein, it doesn't take more than a moment for most to remember a time when falsifying or modifying information would make the relational or professional impact a bit more palatable. Pure honesty can hurt, it can hurt a lot. Despite the ethical quandary it seems that in my experience, honesty rarely has as much of a negative impact as dishonesty. Even still, when honesty does have a negative impact it seems that it is usually because it finds itself on the tail end of all the dishonesty that necessitated honesty in the first place. For that reason I devote the crux of my mental health work on radical honesty and accountability. This doesn't mean I lack the common sense to neglect social cordiality. It means when I make mistakes, offend, or do wrong, I own it. I strive to mitigate blame-shifting, minimization, and fault finding and adopt an attitude of extreme ownership.",
            Communication: "My first passion. As the youngest of five in an extroverted family environment, communication was the currency of attention. intellectual development was of little use until effective and competent communication laid the foundation for it. I believe there are few assets that compete with the power of a well formed sentence. It seems that history relays unequivocally that vision, military-expansion, revolution, religion, ethics, romance, literature, business, the sciences, and most para-cultural relics or technological advances were channeled via the medium of communication. As far as I can tell, the 'word' in its most abstract meaning is the greatest power that humans can harness. I would be dishonest if I said I have no desire to leave this world better than I came into it. For this reason I have consciously devoted my entire life to master communication. This devotion manifests itself in a variety of character traits and skills. High openness and curiosity allow me to be able to find common ground with almost anyone. Coupling that with a desire to understand and help others has privileged me with many opportunities to work with and serve others."
        },
        Hobbies: ['Reading', 'Coding', 'Cooking', 'Building things', 'video games', 'religious studies', 'language', 'music',]
    }